import { FormattedMessage } from 'react-intl';
import { map } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';

import { Button } from 'components/common/button';
import { LISTING_PAGE_ROUTE } from 'constants/routes';
import {
  SectionFeaturedWrapper,
  SectionDescription,
  FeaturesContainer,
  FeatureWrapper,
} from './styles';
import { Container } from '../common/container';
import messages, { MessageKeys } from './messages';
import { features } from './constants';

function SectionFeatured() {
  function renderFeature({ imageName, messageKey }: RenderFeatureProps) {
    return (
      <FeatureWrapper key={messageKey}>
        <Image
          src={`/static/images/benefits/${imageName}`}
          alt={messageKey}
          width={45}
          height={45}
        />
        <p>
          <FormattedMessage {...messages[messageKey]} />
        </p>
      </FeatureWrapper>
    );
  }

  return (
    <SectionFeaturedWrapper>
      <Container size="lg">
        <h2>
          <FormattedMessage {...messages.header} />
        </h2>
        <SectionDescription>
          <FormattedMessage {...messages.description} />
        </SectionDescription>
        <FeaturesContainer>{map(features, renderFeature)}</FeaturesContainer>
        <Link href={LISTING_PAGE_ROUTE}>
          <Button
            className="claim-cash-bonus-cta"
            type="button"
            mode="mediumGreen"
          >
            <FormattedMessage {...messages.claimCashBonus} />
          </Button>
        </Link>
      </Container>
    </SectionFeaturedWrapper>
  );
}

interface RenderFeatureProps {
  imageName: string;
  messageKey: MessageKeys;
}

export default SectionFeatured;
