import styled from 'styled-components';

import { device } from 'styles/breakpoints';

export const SellWithRichrWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.whitePallet3};
  padding-top: 60px;
  display: flex;
  justify-content: center;

  @media ${device.tablet} {
    padding-top: 40px;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.blackPallet3};

    > h2 {
      width: 100%;
      max-width: 1142px;
      font-size: 45px;
      line-height: 1.33;
      font-weight: 700;
      text-align: center;
      margin-bottom: 15px;

      @media ${device.laptop} {
        padding: 0 20px;
        font-size: 32px;
        line-height: 1.37;
      }

      @media ${device.tablet} {
        padding: 0;
        font-size: 25px;
        line-height: 1.44;
        margin-bottom: 10px;
      }
    }
  }
`;

export const StepsContainer = styled.div`
  margin: 80px 60px 0;

  @media ${device.laptop} {
    margin: 80px 40px 0;
  }

  @media ${device.tablet} {
    margin: 40px 0 0 0;
  }
`;

export const StepLayout = styled.div<{ direction?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 120px;

  @media ${device.tablet} {
    flex-direction: ${({ direction }) =>
      direction ? 'column-reverse' : 'column'};
    margin-bottom: 0;
  }
`;

export const ImageWrapper = styled.div<{ leftAligned?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media ${device.tablet} {
    margin-bottom: 80px;
    align-items: center;
  }
`;

export const TitlesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .badge-insurance-image {
    margin-right: 57px;
  }

  @media ${device.tablet} {
    .badge-insurance-image {
      margin-right: 29px;
    }

    margin-bottom: 25px;
  }
`;

export const StepWrapper = styled.div`
  width: 50%;

  @media ${device.tablet} {
    width: 100%;
  }
`;

export const StepCounter = styled.div`
  background-color: ${({ theme }) => theme.colors.green};
  width: 79px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.34;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;

  @media ${device.tablet} {
    width: 69px;
    height: 35px;
    font-size: 20px;
    line-height: 1.35;
    margin-bottom: 15px;
  }
`;

export const StepTitle = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 1.35;
  margin-bottom: 20px;

  @media ${device.tablet} {
    font-size: 22px;
    line-height: 1.36;
    margin-bottom: 15px;
    width: 75%;
  }
`;

export const StepDescription = styled.h6`
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 40px;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.15;
    margin-bottom: 30px;
    font-weight: 500;
  }
`;

export const StepListWrapper = styled.ul`
  color: ${({ theme }) => theme.colors.black};
`;

export const StepListItem = styled.li<{ adjustMargin?: boolean }>`
  font-size: 17px;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: ${({ adjustMargin }) => (adjustMargin ? 0 : '30px')};

  span {
    position: relative;
    left: 20px;
    display: inline-block;
    width: calc(100% - 20px);
  }

  &::before {
    background-color: ${({ theme }) => theme.colors.green};
    border-radius: 50%;
    content: '';
    display: inline-block;
    margin-right: -10px;
    margin-bottom: 2px;
    height: 10px;
    width: 10px;
    vertical-align: top;
    margin-top: 7px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    margin-bottom: 20px;

    &::before {
      height: 8px;
      width: 8px;
      margin-top: 6px;
    }
  }
`;

export const LogoWrapper = styled.p`
  display: flex;
  align-items: flex-start;
  gap: 50px;
  margin: 18px 0 38px;

  @media ${device.tablet} {
    gap: 28px;
    margin: 18px 0 20px;
  }
`;

export const LogoText = styled.p`
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  margin: 5px 0 0 20px;

  @media ${device.tablet} {
    font-style: normal;
    margin: 5px 0 0 15px;
  }
`;

export const Note = styled.p`
  font-size: 20px;
  font-weight: 500;

  @media ${device.tablet} {
    font-size: 16px;
  }
`;
