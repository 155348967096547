import styled from 'styled-components';

import { device } from 'styles/breakpoints';
import { media } from './constants';

const Wrapper = styled.section`
  padding: 80px 70px 110px;
  background-color: ${({ theme }) => theme.colors.whitePallet3};
  position: relative;

  .bottom-ring {
    background: url(${media.ring}) no-repeat;
    background-size: 143px 116px;
    position: absolute;
    width: 143px;
    height: 116px;
    bottom: 0;
    right: 0;
  }

  @media ${device.mobile} {
    flex-direction: column-reverse;
    padding: 60px 20px 80px;
  }

  @media ${device.tablet} {
    .bottom-ring {
      display: none;
    }
  }
`;

const Fund = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column-reverse;
  }
`;

const CalculateSavingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 94px;

  @media ${device.tablet} {
    margin-right: 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: min-content;

  span {
    z-index: 10;
  }

  .dots-top {
    position: absolute;
    width: 250px;
    height: 115px;
    top: -71px;
    left: -49px;
    background: url(${media.dots}) no-repeat center center / cover;
  }

  .dots-bottom {
    position: absolute;
    width: 250px;
    height: 115px;
    bottom: -69px;
    right: -49px;
    background: url(${media.dots}) no-repeat center center / cover;
  }

  @media ${device.tablet} {
    display: flex;
    justify-content: center;

    .dots-top,
    .dots-bottom {
      width: 69px;
      height: 69px;
      background: url(${media.dotsMobile}) no-repeat center center / cover;
    }

    .dots-top {
      top: -26px;
      left: -20px;
    }

    .dots-bottom {
      bottom: -26px;
      right: -20px;
    }
  }
`;

const FundTop = styled.div`
  h1 {
    font-size: 45px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    line-height: 1.2;
    margin-bottom: 20px;
  }

  p {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 60px;
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
      font-size: 25px;
      margin: 40px 0 15px;
    }

    p {
      font-size: 16px;
      line-height: 1.31;
      text-align: center;
      margin-bottom: 40px;
    }
  }
`;

const FundTabs = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    line-height: 1.214;
  }

  @media ${device.tablet} {
    align-items: center;
  }
`;

const FundTabsButtons = styled.div`
  width: 335px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.green};
  padding: 4px;
  display: flex;
  border-radius: 2px;

  > button {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 2px;

    &.active {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.green};
    }
  }
`;

const FundRange = styled.div`
  width: 100%;
  max-width: 920px;
  position: relative;
  margin-top: 40px;

  .range-value-points {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    color: ${({ theme }) => theme.colors.grayPallet9};
  }

  .fund-range-track {
    height: 3px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.grayPallet19};
  }

  .fund-range-picker {
    position: absolute;
    height: 9px;
    width: 9px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.bluePallet5};
    top: -3px;
    display: flex;
    justify-content: center;

    > span {
      width: 9px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.grayPallet19};
      border-radius: 50%;

      &.active {
        background-color: ${({ theme }) => theme.colors.green};
      }
    }

    &:nth-child(1) {
      left: calc(10% - 4px);
    }

    &:nth-child(2) {
      left: calc(20% - 4px);
    }

    &:nth-child(3) {
      left: calc(30% - 4px);
    }

    &:nth-child(4) {
      left: calc(40% - 4px);
    }
  }

  .fund-range-thumb {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.greenPallet6};
    width: 23px;
    height: 23px;
    outline: none;
    border-radius: 50%;

    &-dot {
      height: 23px;
      width: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      > span {
        height: 15px;
        width: 15px;
        background-color: ${({ theme }) => theme.colors.greenPallet5};
        border-radius: 50%;
      }
    }
  }

  @media ${device.tablet} {
    margin-top: 60px;
  }
`;

const FundInfo = styled.div`
  margin-top: 60px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;

  .fund-info-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.grayPallet20};
    padding: 28px 20px;

    span {
      color: ${({ theme }) => theme.colors.grayPallet9};
    }

    &-count {
      color: ${({ theme }) => theme.colors.green};
      font-size: 32px;
      font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
      line-height: 1.21;
      text-align: center;
    }

    &-title {
      color: ${({ theme }) => theme.colors.grayPallet10};
      font-size: 17px;
      font-weight: ${({ theme }) => theme.typography.fontWeights.semibold};
      line-height: 1.35;
      text-align: center;
      width: 262px;
      margin-top: 10px;
    }
  }

  @media ${device.tablet} {
    flex-direction: column;
    margin-top: 80px;

    .fund-info-box {
      align-items: center;

      &-count {
        font-size: 25px;
        line-height: 1.2;
      }

      &-title {
        font-size: 16px;
        line-height: 1.31;
      }
    }
  }
`;

const Estimates = styled.div`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: 1.31;
  color: ${({ theme }) => theme.colors.grayPallet9};
  margin: 30px 0 40px;

  .estimate-year {
    color: ${({ theme }) => theme.colors.blackPallet6};
  }

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 1.35;
    text-align: center;
    margin: 20px 0 30px;
  }
`;

const DisplayPrice = styled.div<{ inputWidth: number }>`
  position: relative;

  .hidden-value {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  input,
  .hidden-value {
    border: none;
    background-color: transparent;
    font-size: 32px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    line-height: 1.21;
    color: ${({ theme }) => theme.colors.green};
  }

  &::after {
    content: '';
    width: ${({ inputWidth }) => `${inputWidth}px`};
    min-width: 150px;
    height: 1px;
    background: ${({ theme }) => theme.colors.grayPallet18};
    position: absolute;
    bottom: -10px;
    left: 0;
  }

  @media ${device.tablet} {
    input {
      font-size: 25px;
      line-height: 1.2;
      width: 150px;
    }

    &::after {
      content: '';
      width: 143px;
    }
  }
`;

export const FundRangeActive = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  max-width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 3px;
  background-color: ${({ theme }) => theme.colors.green};
  pointer-events: none;
`;

export default {
  Wrapper,
  Fund,
  FundTop,
  FundTabs,
  FundTabsButtons,
  FundRange,
  FundInfo,
  Estimates,
  Content,
  CalculateSavingsWrapper,
  ImageWrapper,
  DisplayPrice,
  FundRangeActive,
};
