import { memo, useEffect, useMemo, useState, useRef } from 'react';
import { Range } from 'react-range';
import CurrencyInput from 'react-currency-input-field';
import { isEqual, isNil, map, toNumber } from 'lodash';
import cx from 'classnames';
import { IRenderThumbParams, IRenderTrackParams } from 'react-range/lib/types';
import { FormattedMessage, useIntl } from 'react-intl';
import NextImage from 'next/image';

import Styled from './styles';
import { numberFormat } from 'utils/helpers';
import messages from './messages';
import {
  buy,
  centerBoxPercent,
  fundRangeSteps,
  max,
  maxInputValue,
  media,
  min,
  rewardBuyPercent,
  rewardSellPercent,
  sell,
  years,
} from './constants';
import Typography from 'components/common/typography';
import { formatCurrency } from 'utils/formatCurrency';

function CalculateSavingsSection() {
  const intl = useIntl();
  const [investmentRange, setInvestmentRange] = useState<number[]>([500000]);
  const [activeTab, setActiveTab] = useState<string>(sell);
  const [inputWidth, setInputWidth] = useState(0);
  const span = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (span.current?.offsetWidth) {
      setInputWidth(span.current.offsetWidth);
    }
  }, [investmentRange]);

  const getAmountInvestedByRichr = useMemo(() => {
    const interestPercent =
      activeTab === sell ? rewardSellPercent : rewardBuyPercent;
    const principal = investmentRange[0] * centerBoxPercent;
    const result = toNumber(
      (principal * (1 + interestPercent) ** years).toFixed(2)
    );

    return result;
  }, [investmentRange, activeTab]);

  const infoBoxes = [
    {
      count: numberFormat(investmentRange[0]),
      title: intl.formatMessage({ ...messages.homeValue }),
    },
    {
      count: numberFormat(
        activeTab === buy
          ? investmentRange[0] * centerBoxPercent
          : investmentRange[0] * centerBoxPercent
      ),
      title: intl.formatMessage({ ...messages.financialSavings }),
    },
    {
      count: numberFormat(getAmountInvestedByRichr),
      title: intl.formatMessage({ ...messages.invest }),
      description: intl.formatMessage(
        { ...messages.returns },
        { percentage: isEqual(activeTab, 'SELL') ? 6 : 3 }
      ),
    },
  ];

  function handleTabChange(tabName: typeof buy | typeof sell) {
    return function handleClick() {
      setActiveTab(tabName);
    };
  }

  function updateRange(values: number[]) {
    setInvestmentRange(values);
  }

  function handleValueChange(value: string | undefined) {
    if (toNumber(value) > maxInputValue) {
      return;
    }

    if (!isNil(value)) {
      setInvestmentRange([toNumber(value)]);
    } else {
      setInvestmentRange([0]);
    }
  }

  function renderTrack({ props, children }: IRenderTrackParams) {
    return (
      <div {...props} className="fund-range-track">
        {children}
      </div>
    );
  }

  function renderInfoBox({ count, title, description }: InfoBoxProps) {
    return (
      <div className="fund-info-box" key={title}>
        <h1 className="fund-info-box-count">{count}</h1>
        <p className="fund-info-box-title">{title}</p>
        {description ? (
          <Typography variant="body1" as="span">
            {description}
          </Typography>
        ) : null}
      </div>
    );
  }

  function renderThumb({ props }: IRenderThumbParams) {
    return (
      <div {...props} className="fund-range-thumb">
        <div className="fund-range-thumb-dot">
          <span />
        </div>
      </div>
    );
  }

  function renderFundTabs() {
    return (
      <Styled.FundTabs>
        <Styled.FundTabsButtons>
          <button
            onClick={handleTabChange(sell)}
            className={cx({ active: activeTab === sell })}
            type="button"
          >
            <p>
              <FormattedMessage {...messages.sell} />
            </p>
          </button>
          <button
            onClick={handleTabChange(buy)}
            className={cx({ active: activeTab === buy })}
            type="button"
          >
            <p>
              <FormattedMessage {...messages.buy} />
            </p>
          </button>
        </Styled.FundTabsButtons>
        <Styled.Estimates>
          <span>
            <FormattedMessage
              {...messages.estimatedValue}
              values={{ percentage: isEqual(activeTab, 'SELL') ? 6 : 3 }}
            />
          </span>
          <span className="estimate-year">{` ${years} years`}</span>
        </Styled.Estimates>
        <Styled.DisplayPrice inputWidth={inputWidth}>
          <span className="hidden-value" ref={span}>
            {formatCurrency({ amount: investmentRange[0] })}
          </span>
          <CurrencyInput
            value={investmentRange[0]}
            onValueChange={handleValueChange}
            prefix="$"
          />
        </Styled.DisplayPrice>
      </Styled.FundTabs>
    );
  }

  function renderFundRange() {
    return (
      <Styled.FundRange>
        {map(fundRangeSteps, (rangeStep) => (
          <div className="fund-range-picker" key={rangeStep}>
            <span
              className={cx({
                active: investmentRange[0] > rangeStep * 100000,
              })}
            />
          </div>
        ))}
        <Range
          min={min}
          max={max}
          values={investmentRange[0] > max ? [max] : investmentRange}
          step={5000}
          onChange={updateRange}
          renderTrack={renderTrack}
          renderThumb={renderThumb}
        />
        <Styled.FundRangeActive
          className="fund-range-active"
          width={`${(investmentRange[0] * 100) / max}%`}
        />
        <div className="range-value-points">
          <span>
            <FormattedMessage {...messages.minValue} />
          </span>
          <span>
            <FormattedMessage {...messages.maxValue} />
          </span>
        </div>
      </Styled.FundRange>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Fund className="fund">
        <Styled.Content>
          <Styled.CalculateSavingsWrapper>
            <Styled.FundTop>
              <h1 className="fund-top-title">
                <FormattedMessage {...messages.title} />
              </h1>
              <p className="fund-top-subtitle">
                <FormattedMessage {...messages.description} />
              </p>
            </Styled.FundTop>
            {renderFundTabs()}
            {renderFundRange()}
          </Styled.CalculateSavingsWrapper>
          <Styled.ImageWrapper>
            <div className="dots-top" />
            <NextImage src={media.heroImage} width={460} height={376} />
            <div className="dots-bottom" />
          </Styled.ImageWrapper>
        </Styled.Content>
        <Styled.FundInfo>{map(infoBoxes, renderInfoBox)}</Styled.FundInfo>
      </Styled.Fund>
      <div className="bottom-ring" />
    </Styled.Wrapper>
  );
}

type InfoBoxProps = {
  count: string;
  title: string;
  description: string;
};

export default memo(CalculateSavingsSection);
