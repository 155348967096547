import NextLink from 'next/link';
import NextImage from 'next/image';
import { FormattedMessage } from 'react-intl';

import { HOME_VALUATION_ROUTE } from 'constants/routes';
import Styled from './styles';
import { useWindow } from 'hooks/useWindow';
import messages from './messages';
import { media } from './constants';
import useTriggerFunnelyticsEvent from 'hooks/useTriggerFunnelyticsEvent';
import { HOME_VALUATION_EVENTS } from 'hooks/useTriggerFunnelyticsEvent/constants';

function SectionDiscover() {
  const { w: width } = useWindow();
  const { createEventTriggerer } = useTriggerFunnelyticsEvent();

  return (
    <Styled.DiscoverLayout>
      <div className="discover-left-img" />
      <Styled.LeftLayout>
        <h1>
          <FormattedMessage
            {...messages.title}
            values={{ apos: <>&apos;</> }}
          />
        </h1>
        <p>
          <FormattedMessage {...messages.description} />
        </p>
        <NextLink href={HOME_VALUATION_ROUTE} passHref>
          <a
            onClick={createEventTriggerer({
              eventName: HOME_VALUATION_EVENTS.START,
              withPathname: true,
              ctaPosition: 'discover-true-value-section',
            })}
          >
            <FormattedMessage {...messages.button} />
          </a>
        </NextLink>
      </Styled.LeftLayout>
      <Styled.RightLayout>
        <div className="discover-right-img" />
        <NextImage
          src={media.main}
          width={width > 1280 ? 326 : 215}
          height={width > 1280 ? 414 : 273}
        />
        <div className="right-layout-ring" />
      </Styled.RightLayout>
      <div className="discover-right-ring" />
    </Styled.DiscoverLayout>
  );
}

export default SectionDiscover;
