import styled from 'styled-components';

import { device } from 'styles/breakpoints';
import { media } from './constants';

const HomepageLayout = styled.div`
  display: flex;
  flex-direction: column;
  background: url(${media.homeBackground}) no-repeat center right / cover;
  height: 100vh;

  @media ${device.tablet} {
    background: url(${media.homeBackgroundMobile}) no-repeat center bottom /
      cover;
  }
`;

const SectionHeroWrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: 0 50px;
  margin-top: 91px;

  > div {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  @media ${device.laptop} {
    > div {
      margin-top: 40px;
    }
  }

  @media ${device.tablet} {
    padding: 0;
    margin-top: 60px;

    > div {
      margin-top: 40px;
    }
  }

  @media ${device.mobile} {
    > div {
      margin-top: 20px;
    }
  }
`;

const SectionTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .hero-title {
    width: 100%;
    max-width: 640px;
    margin-top: 20px;
    font-size: 55px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    line-height: 1.218;
    color: ${({ theme }) => theme.colors.blackPallet6};
  }

  .hero-description {
    font-size: 20px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    line-height: 1.2;
    width: 100%;
    max-width: 648px;
  }

  @media ${device.tablet} {
    gap: 15px;

    .hero-title {
      margin-top: 30px;
      font-size: 32px;
      font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
      line-height: 1.2;
    }

    .hero-description {
      font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
      font-size: 16px;
      line-height: 1.312;

      .plus-icon {
        left: 7px;
        bottom: 5px;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 250px;
  margin-top: 30px;

  a {
    display: flex;
    height: 70px;
    font-size: 17px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    line-height: 1.21;
    text-align: center;
    border-radius: 0;
    padding: 22px 44px;
    height: initial;
    transition: 0.2s;
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      opacity: 0.8;
    }
  }

  @media ${device.tablet} {
    margin-top: 20px;

    a {
      padding: 10px 22px;
      width: max-content;
    }
  }
`;

const HeroReview = styled.div`
  margin-top: 40px;
  flex-grow: 1;
  display: flex;
  max-width: 412px;

  > div {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }

  .slide-wrapper {
    display: flex;
    gap: 19px;
    justify-content: space-between;
  }

  .swiper-pagination-bullet {
    background: ${({ theme }) => theme.colors.grayPallet18};
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.colors.blackPallet6};
  }

  img {
    border-radius: 5px;
  }

  @media ${device.tablet} {
    margin-top: 40px;

    .slide-wrapper span {
      flex-grow: 1;
    }
  }

  @media ${device.mobile} {
    height: 98px;
  }
`;

const PlusIcon = styled.span`
  position: relative;
  left: 10px;
  bottom: 7px;
  color: ${({ theme }) => theme.colors.greenPallet5};

  @media ${device.tablet} {
    left: 7px;
    bottom: 5px;
  }
`;

const HiddenText = styled.p<{ show: boolean }>`
  visibility: ${({ show }) => (show ? 'initial' : 'hidden')};
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.greenPallet5};

  @media ${device.tablet} {
    margin: 5px 0 10px;
  }
`;

const FDICWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
  align-items: center;

  p {
    max-width: 226px;
    min-width: 170px;
    text-align: initial;
    color: ${({ theme }) => theme.colors.grayPallet10};

    @media ${device.tablet} {
      width: 50%;
    }
  }
`;

export default {
  HomepageLayout,
  SectionHeroWrapper,
  SectionTop,
  ButtonWrapper,
  HeroReview,
  PlusIcon,
  HiddenText,
  FDICWrapper,
};
