import { defineMessages } from 'react-intl';

export const scope = 'app.components.section-calculate-savings';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Calculate your savings and potential investment success',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Discover your possible reward by adjusting the home value',
  },
  sell: {
    id: `${scope}.sell`,
    defaultMessage: 'Sell',
  },
  buy: {
    id: `${scope}.buy`,
    defaultMessage: 'Buy',
  },
  estimatedValue: {
    id: `${scope}.estimatedValue`,
    defaultMessage: 'Estimated value of what {percentage}% is worth in',
  },
  homeValue: {
    id: `${scope}.homeValue`,
    defaultMessage: 'Your home value',
  },
  financialSavings: {
    id: `${scope}.financialSavings`,
    defaultMessage: 'Financial Savings with Richr Homes',
  },
  invest: {
    id: `${scope}.invest`,
    defaultMessage: 'Invested with Richr Money',
  },
  minValue: {
    id: `${scope}.initialValue`,
    defaultMessage: '0',
  },
  maxValue: {
    id: `${scope}.maxValue`,
    defaultMessage: '2M+',
  },
  returns: {
    id: `${scope}.returns`,
    defaultMessage: 'In 30 years, assuming {percentage}% rate of return',
  },
});
