import { defineMessages } from 'react-intl';

export const scope = 'app.components.SectionSellWithRichr';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'The Richr Experience',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Experience the seamless and best class services made just for you. Explore the Richr difference in home selling.',
  },
  insuranceAltText: {
    id: `${scope}.insuranceAltText`,
    defaultMessage: 'First american title insurance company logo',
  },
  fidelityAltText: {
    id: `${scope}.fidelityAltText`,
    defaultMessage: 'Fidelity national title agency logo',
  },
  FDICAltText: {
    id: `${scope}.FDICAltText`,
    defaultMessage: 'FDIC logo',
  },
  SIPCAltText: {
    id: `${scope}.SIPCAltText`,
    defaultMessage: 'SIPC logo',
  },
  stepOneTitle: {
    id: `${scope}.title`,
    defaultMessage: 'Sell with Confidence using Richr Homes',
  },
  stepOneSubText: {
    id: `${scope}.subText`,
    defaultMessage:
      'Let us find the perfect buyer for your home! We offer the following benefits:',
  },
  stepOneAltText: {
    id: `${scope}.stepOneAltText`,
    defaultMessage: 'step one',
  },
  stepOnePointOne: {
    id: `${scope}.pointOne`,
    defaultMessage: `Maximize your home's exposure with advertising on MLS and syndication to over 750 websites, including Zillow, Trulia, Redfin, and Realtor.com, where 92% of all home sales take place.`,
  },
  stepOnePointTwo: {
    id: `${scope}.pointTwo`,
    defaultMessage:
      'Get a professional home value estimate to ensure you receive the best sales price.',
  },
  stepOnePointThree: {
    id: `${scope}.pointThree`,
    defaultMessage:
      'Enjoy hassle-free selling with our concierge services, including contract review, disclosures, and closing documents.',
  },
  stepOnePointFour: {
    id: `${scope}.pointFour`,
    defaultMessage: 'Showcase your home in its best light with HD photography.',
  },
  stepTwoTitle: {
    id: `${scope}.title`,
    defaultMessage: 'Close with Ease using Richr Title',
  },
  stepTwoSubText: {
    id: `${scope}.subText`,
    defaultMessage:
      'Rest easy knowing Richr Title has your back during the closing process. Our services include:',
  },
  stepTwoAltText: {
    id: `${scope}.stepTwoAltText`,
    defaultMessage: 'step two',
  },
  stepTwoPointOne: {
    id: `${scope}.pointOne`,
    defaultMessage:
      'Escrow and Title/Settlement Services, providing a seamless closing experience.',
  },
  stepTwoPointTwo: {
    id: `${scope}.pointTwo`,
    defaultMessage:
      'Choose to close digitally or through a mobile notary for added convenience.',
  },
  stepTwoPointThree: {
    id: `${scope}.pointThree`,
    defaultMessage:
      'Experience a secure and interactive closing process with our cloud-based software.',
  },
  stepTwoPointFour: {
    id: `${scope}.pointFour`,
    defaultMessage:
      'Receive peace of mind with Title Insurance provided by leading national underwriters.',
  },
  stepThreeTitle: {
    id: `${scope}.title`,
    defaultMessage: 'Fund Your Future with  Richr Money',
  },
  stepThreeSubText: {
    id: `${scope}.subText`,
    defaultMessage: 'Introducing the limited Day One Rewards with Richr Money',
  },
  stepThreeAltText: {
    id: `${scope}.stepThreeAltText`,
    defaultMessage: 'step three',
  },
  stepThreePointOne: {
    id: `${scope}.pointOne`,
    defaultMessage:
      'List your home to earn daily cash bonuses directly into your Richr Money account and watch your money grow.',
  },
  stepThreePointTwo: {
    id: `${scope}.pointTwo`,
    defaultMessage:
      'Gain exclusive access to open a Richr Money High-Yield Savings Account and earn up to 4.83%.',
  },
  stepThreePointThree: {
    id: `${scope}.pointThree`,
    defaultMessage:
      'Enjoy peace of mind with a trusted team and a secure, straightforward platform that helps home sellers achieve financial goals.',
  },
  stepThreePointFour: {
    id: `${scope}.pointFour`,
    defaultMessage:
      'Our special daily rewards are available for a limited period. Act now Secure your spot and maximize your earnings.',
  },
  stepThreeLogoText: {
    id: `${scope}.stepThreeLogoText`,
    defaultMessage: '*FDIC insured and APY subject to change',
  },
  stepThreeNote: {
    id: `${scope}.stepThreeNote`,
    defaultMessage:
      'List, earn, and secure your future. Every step with Richr is a step towards greater financial well-being.',
  },
});
