import Head from 'next/head';
import { useEffect } from 'react';
import styled from 'styled-components';

import SectionClients from 'containers/section-clients';
import SectionFeatured from 'components/section-featured';
import SectionHero from 'containers/section-hero';
import SectionListedOn from 'components/section-listed-on';
import SectionSellWithRichr from 'components/section-sell-with-richr';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { toggleIsVerifiedBox } from 'store/slices/global';
import { authSelector } from 'store/slices/auth/selector';
import SectionDiscover from 'containers/section-discover';
import SectionSellWithEase from 'containers/section-sell-with-ease';
import SectionCalculateSavings from 'containers/section-calculate-savings';
import Layout from 'components/layout';
// import Banner from 'components/banner';

function Home() {
  const { user } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user.data && !user.data.isVerified) {
      dispatch(toggleIsVerifiedBox());
    }
  }, [dispatch, user.data]);

  return (
    <>
      {/* <Banner /> */}
      <LayoutWrapper>
        <Layout>
          <Head>
            <title>Home</title>
          </Head>
          <SectionHero />
          <SectionFeatured />
          <SectionSellWithRichr />
          <SectionListedOn />
          <SectionDiscover />
          <SectionClients />
          <SectionCalculateSavings />
          <SectionSellWithEase />
        </Layout>
      </LayoutWrapper>
    </>
  );
}

export default Home;

const LayoutWrapper = styled.div`
  position: relative;
`;
