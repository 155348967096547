import React, { memo } from 'react';
import NextImage from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { FormattedMessage } from 'react-intl';
import NextLink from 'next/link';
import 'swiper/css';
import 'swiper/css/pagination';

import { Container } from 'components/common/container';
import Styled from './styles';
import { useWindow } from 'hooks/useWindow';
import messages from './messages';
import { media } from './constants';
import { LISTING_PAGE_ROUTE } from 'constants/routes';
import Typography from 'components/common/typography';
import useTriggerFunnelyticsEvent from 'hooks/useTriggerFunnelyticsEvent';
import { LISTING_EVENTS } from 'hooks/useTriggerFunnelyticsEvent/constants';

function SectionHero() {
  const { w: width } = useWindow();
  const { createEventTriggerer } = useTriggerFunnelyticsEvent();

  function renderDesktopReviews() {
    return (
      <div>
        <NextImage
          src={media.review1}
          objectFit="cover"
          width={191}
          height={72}
        />
        <NextImage
          src={media.review2}
          objectFit="cover"
          width={191}
          height={72}
        />
        <NextImage
          src={media.review3}
          objectFit="cover"
          width={191}
          height={72}
        />
        <NextImage
          src={media.review4}
          objectFit="cover"
          width={191}
          height={72}
        />
      </div>
    );
  }

  function renderMobileReviews() {
    return (
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        slidesPerGroupSkip={1}
        pagination={{ clickable: true }}
        modules={[Pagination]}
      >
        <SwiperSlide>
          <div className="slide-wrapper">
            <NextImage
              src={media.reviewOneMobile}
              objectFit="cover"
              width={158}
              height={65}
            />
            <NextImage
              src={media.reviewTwoMobile}
              objectFit="cover"
              width={158}
              height={65}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-wrapper">
            <NextImage
              src={media.reviewThreeMobile}
              objectFit="cover"
              width={158}
              height={65}
            />
            <NextImage
              src={media.reviewFourMobile}
              objectFit="cover"
              width={158}
              height={65}
            />
          </div>
        </SwiperSlide>
      </Swiper>
    );
  }

  return (
    <Styled.HomepageLayout>
      <Styled.SectionHeroWrapper>
        <Container size="lg">
          <Styled.SectionTop>
            <h1 className="hero-title">
              <FormattedMessage {...messages.title} />
            </h1>
            <div className="hero-description">
              <p>
                <FormattedMessage {...messages.description} />
                {/* <Styled.PlusIcon
                  onClick={toggleHiddenText}
                  onMouseOver={toggleHiddenText}
                  onMouseOut={toggleHiddenText}
                >
                  +
                </Styled.PlusIcon> */}
              </p>
              {/* <Styled.HiddenText show={showHiddenText}>
                <Typography variant="body2" fontWeight="medium" as="span">
                  <FormattedMessage {...messages.hiddenInfo} />
                </Typography>
              </Styled.HiddenText> */}
            </div>
          </Styled.SectionTop>
          <Styled.ButtonWrapper>
            <NextLink href={LISTING_PAGE_ROUTE} passHref>
              <a
                onClick={createEventTriggerer({
                  eventName: LISTING_EVENTS.START,
                  withPathname: true,
                  ctaPosition: 'hero-section',
                })}
              >
                <FormattedMessage {...messages.start} />
                <NextImage
                  src={media.rightArrow}
                  objectFit="cover"
                  width={20}
                  height={21}
                />
              </a>
            </NextLink>
          </Styled.ButtonWrapper>
          <Styled.FDICWrapper>
            <NextImage
              src={media.fdic}
              width={width > 768 ? 66 : 59}
              height={width > 768 ? 47 : 42}
            />
            <Typography variant="body1" as="p" fontWeight="medium">
              <FormattedMessage {...messages.fdicText} />
            </Typography>
          </Styled.FDICWrapper>
          <Styled.HeroReview>
            {width > 480 ? renderDesktopReviews() : renderMobileReviews()}
          </Styled.HeroReview>
        </Container>
      </Styled.SectionHeroWrapper>
    </Styled.HomepageLayout>
  );
}

export default memo(SectionHero);
