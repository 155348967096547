import { defineMessages } from 'react-intl';

export const scope = 'app.components.section-clients';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Join our community of Happy Clients',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Get 2% Cash Back and Pay 1% in Fees with Richr',
  },
  saved: {
    id: `${scope}.saved`,
    defaultMessage: 'Saved',
  },
  cardOneText: {
    id: `${scope}.cardOne`,
    defaultMessage:
      'Working with Richr to sell our home was the best decision we could have made. Getting free MLS exposure, while saving tens of thousands of dollars in commission ($24k to be exact), was a no-brainer. The company is extremely easy to work with, very responsive to any questions we had, and offered guidance through every step of the process. We have already referred several neighbors to Richr, and will continue to do so',
  },
  cardTwoText: {
    id: `${scope}.cardTwo`,
    defaultMessage:
      "They literally saved me $9600...it didn't cost me anything, I got my property listed on the MLS, got a buyer, their realtor got 1% from us, and we just closed. Great, easy experience.",
  },
  cardThreeText: {
    id: `${scope}.cardThree`,
    defaultMessage:
      'We sold our home and saved $24,600 on commission by using Richr!! ...For anyone looking to sell their home without a realtor, Richr is the company that can help you do that. They will get your home on the MLS,..you also get to choose how much of a commission you want to pay to any realtors that represent the buyers. It was truly a pleasure selling our home through Richr. We sold at the price we expected, and saved thousands! Anyone that is skeptical, this is for real.',
  },
  cardFourText: {
    id: `${scope}.cardTwo`,
    defaultMessage:
      "I just closed on the sale of my house and if you were to tell me there was a way to save 4% of the commission when I first was considering to sell, I wouldn't believe it. But it's true! At first I was hesitant but we decided to give it a shot and I am so happy we did! This is the real deal and I have already recommended Richr to some of my friends! If you're looking to save big bucks and have control of the sale of your home, this is the way to go. I can't thank you enough!",
  },
  cardFiveText: {
    id: `${scope}.cardThree`,
    defaultMessage:
      'Richr made selling my house effortless. Highly recommend their professional expertise.',
  },
  cardOnePerson: {
    id: `${scope}.cardOne`,
    defaultMessage: 'Shelia',
  },
  cardTwoPerson: {
    id: `${scope}.cardTwo`,
    defaultMessage: 'Aaron',
  },
  cardThreePerson: {
    id: `${scope}.cardThree`,
    defaultMessage: 'Maria',
  },
  cardFourPerson: {
    id: `${scope}.cardFourPerson`,
    defaultMessage: 'Lorena',
  },
  cardOneSaved: {
    id: `${scope}.cardOneSaved`,
    defaultMessage: '$24,150',
  },
  cardTwoSaved: {
    id: `${scope}.cardTwoSaved`,
    defaultMessage: '$9,600',
  },
  cardThreeSaved: {
    id: `${scope}.cardThreeSaved`,
    defaultMessage: '$24,600',
  },
  cardFourSaved: {
    id: `${scope}.cardFourSaved`,
    defaultMessage: '$9,600',
  },
});
