import styled from 'styled-components';

import { device } from 'styles/breakpoints';

export const SectionFeaturedWrapper = styled.section`
  margin: 100px 0;
  display: flex;
  justify-content: center;

  @media ${device.tablet} {
    margin: 60px 0;
  }

  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.blackPallet3};

    > h2 {
      width: 100%;
      max-width: 1142px;
      font-size: 45px;
      line-height: 1.33;
      font-weight: 700;
      position: relative;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 15px;

      @media ${device.laptop} {
        padding: 0 20px;
        font-size: 32px;
        line-height: 1.37;
        margin-bottom: 20px;
      }

      @media ${device.tablet} {
        padding: 0;
        font-size: 25px;
        line-height: 1.44;
        margin-bottom: 12px;
      }
    }
  }

  .claim-cash-bonus-cta {
    margin: 17px auto 0;

    @media ${device.tablet} {
      margin: 20px auto 0;
    }
  }
`;

export const SectionDescription = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 1.15;
  text-align: center;
  max-width: 627px;
  margin: 0 auto;

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;

  @media ${device.tablet} {
    flex-direction: column;
    margin-top: 27px;
    align-items: center;
  }
`;

export const FeatureWrapper = styled.div`
  min-width: 407px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  border: 1px solid ${({ theme }) => theme.colors.whitePallet4};
  box-shadow: 0px 7px 12px 0px ${({ theme }) => theme.colors.blackPallet3}12;
  border-radius: 5px;
  margin: 0 15px 43px 15px;

  p {
    margin-left: 24px;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.11;
  }

  @media ${device.tablet} {
    min-width: 100%;
    padding: 20px 13px;
    margin: 0 0 25px 0;
    justify-content: center;
  }

  @media ${device.mobile} {
    justify-content: flex-start;
  }
`;
