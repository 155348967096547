import { MessageKeys } from './messages';

export const features: FeatureDescription[] = [
  {
    messageKey: 'featureHouse',
    imageName: 'houses.svg',
  },
  {
    messageKey: 'featureSupport',
    imageName: 'support.svg',
  },
  {
    messageKey: 'featureTeam',
    imageName: 'closing-team.svg',
  },
  {
    messageKey: 'featureFee',
    imageName: 'fee.svg',
  },
  {
    messageKey: 'featureRiskFree',
    imageName: 'risk-free.svg',
  },
];

interface FeatureDescription {
  messageKey: MessageKeys;
  imageName: string;
}
