import styled from 'styled-components';

import { device } from 'styles/breakpoints';

const Wrapper = styled.div`
  padding: 100px 70px 80px;

  h1 {
    font-size: 45px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    line-height: 1.2;
    text-align: center;
    max-width: 635px;
    margin: auto;
  }

  @media ${device.tablet} {
    padding: 60px 20px;

    h1 {
      font-size: 25px;
      text-align: center;
    }
  }
`;

const CardsContainer = styled.div`
  display: flex;
  gap: 29px;
  margin-top: 80px;

  @media ${device.tablet} {
    margin-top: 40px;
    display: initial;
  }
`;

const Card = styled.div`
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.blackPallet5};
  background-color: ${({ theme }) => theme.colors.grayPallet20};
  flex: 1 1 0;
  transition: 0.2s;
  height: 100%;
  box-shadow: 0px 6px 15px 0px ${({ theme }) => theme.colors.blackPallet3}0f;
  border: 1px solid ${({ theme }) => theme.colors.grayPallet19};

  .card-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .money-saved {
    display: flex;
    gap: 5px;
    align-items: flex-end;
  }

  .card-description {
    margin: 25px 0;
    font-size: 17px;
    line-height: 1.35;
    text-align: center;
  }

  .client-image-wrapper {
    width: 60px;
    flex-grow: 0;
  }

  .client-name {
    font-size: 17px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    line-height: 1.35;
    color: ${({ theme }) => theme.colors.blackPallet5};
  }

  svg {
    color: ${({ theme }) => theme.colors.green};
  }

  &:hover {
    border: 1px solid transparent;
    /* To be fixed */
    /* box-shadow: 0px 15px 55px 3px ${({ theme }) =>
      theme.colors.blackPallet4}33; */
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.white};

    svg {
      color: ${({ theme }) => theme.colors.white};
    }

    .client-name {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media ${device.tablet} {
    color: ${({ theme }) => theme.colors.blackPallet5};
    background-color: ${({ theme }) => theme.colors.grayPallet20};
    padding: 32px;
    margin: auto;

    svg {
      color: ${({ theme }) => theme.colors.green};
    }

    .card-description {
      font-size: 14px;
      margin: 20px 0;
    }

    .client-name {
      font-size: 14px;
    }

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.grayPallet19};
      color: ${({ theme }) => theme.colors.blackPallet5};
      background-color: ${({ theme }) => theme.colors.grayPallet20};

      svg {
        color: ${({ theme }) => theme.colors.green};
      }

      .client-name {
        color: ${({ theme }) => theme.colors.blackPallet5};
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;

  .pagination-bullets {
    display: flex;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    background: ${({ theme }) => theme.colors.grayPallet19};
    opacity: 1;
    width: 15px;
    height: 15px;
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.colors.green};
  }

  @media ${device.tablet} {
    gap: 30px;

    .swiper-pagination-bullet {
      width: 11px;
      height: 11px;
    }

    .swiper-slide {
      height: auto;
    }
  }
`;

export default { Wrapper, Card, CardsContainer, Content };
