import NextLink from 'next/link';
import NextImage from 'next/image';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import Styled from './styles';
import { media } from './constants';
import { LISTING_PAGE_ROUTE } from 'constants/routes';
import useTriggerFunnelyticsEvent from 'hooks/useTriggerFunnelyticsEvent';
import { LISTING_EVENTS } from 'hooks/useTriggerFunnelyticsEvent/constants';

function SectionSellWithEase() {
  const { createEventTriggerer } = useTriggerFunnelyticsEvent();

  return (
    <Styled.Wrapper>
      <Styled.ImageWrapper>
        <NextImage src={media.sellWithRichr} width={645} height={570} />
      </Styled.ImageWrapper>
      <Styled.Content>
        <h1>
          <FormattedMessage {...messages.title} />
        </h1>
        <p>
          <FormattedMessage {...messages.description} />
        </p>
        <NextLink href={LISTING_PAGE_ROUTE} passHref>
          <a
            onClick={createEventTriggerer({
              eventName: LISTING_EVENTS.START,
              withPathname: true,
              ctaPosition: 'maximize-potential-section',
            })}
          >
            <FormattedMessage {...messages.button} />
            <NextImage
              src={media.rightArrow}
              objectFit="cover"
              width={20}
              height={21}
            />
          </a>
        </NextLink>
      </Styled.Content>
    </Styled.Wrapper>
  );
}

export default SectionSellWithEase;
