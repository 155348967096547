export const media = {
  heroImage: '/static/images/home/calculate.jpg',
  dots: '/static/icons/dots.svg',
  ring: '/static/images/home/ring.svg',
  dotsMobile: '/static/icons/dots-mobile.svg',
};

export const rewardSellPercent = 0.06;
export const rewardBuyPercent = 0.03;
export const centerBoxPercent = 0.02;
export const min = 0;
export const max = 2000000;
export const buy = 'BUY';
export const sell = 'SELL';
export const years = 30;
export const fundRangeSteps = [2, 4, 6, 8];
export const maxInputValue = 10000000000000;
