import { defineMessages } from 'react-intl';

export const scope = 'app.components.section-sell-with-ease';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Maximize Your Property's Potential: Sell with Ease",
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Simplify the selling process and connect with thousands of interested buyers. List your property today and let us help you maximize its potential and get the best value for your home.',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: 'Start listing now',
  },
});
