import { FormattedMessage } from 'react-intl';
import NextImage from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { PaginationOptions } from 'swiper/types';
import { map } from 'lodash';
import 'swiper/css';
import 'swiper/css/pagination';

import messages from './messages';
import Styled from './styles';
import { useWindow } from 'hooks/useWindow';
import Quotes from './quotes';
import { CardNames, cards, media } from './constants';
import Typography from 'components/common/typography';

function SectionClients() {
  const { w: width } = useWindow();
  const pagination: PaginationOptions = {
    el: '.pagination-bullets',
    clickable: true,
    renderBullet: (_, className) => `<span class=${className}></span>`,
  };

  function renderCard(cardNumber: number, name: CardNames) {
    return (
      <Styled.Card>
        <Quotes />
        <p className="card-description">
          <FormattedMessage {...messages[`${name}Text`]} />
        </p>
        <div className="card-details">
          <div className="client-image-wrapper">
            <NextImage
              src={`/static/images/home/client-${cardNumber}.png`}
              width={60}
              height={60}
            />
          </div>
          <NextImage src={media.stars} width={89} height={13} />
          <p className="client-name">
            <FormattedMessage {...messages[`${name}Person`]} />
          </p>
          <div className="money-saved">
            <Typography variant="body3" as="span" fontWeight="semibold">
              <FormattedMessage {...messages.saved} />
            </Typography>
            <Typography variant="h5" as="span" fontWeight="bold">
              <FormattedMessage {...messages[`${name}Saved`]} />
            </Typography>
          </div>
        </div>
      </Styled.Card>
    );
  }

  return (
    <Styled.Wrapper>
      <h1>
        <FormattedMessage {...messages.title} />
      </h1>
      <Styled.Content>
        <Styled.CardsContainer>
          <Swiper
            slidesPerView={width > 768 ? 3 : 1}
            spaceBetween={20}
            slidesPerGroupSkip={1}
            pagination={pagination}
            modules={[Pagination]}
          >
            {map(cards, (cardName, index) => (
              <SwiperSlide key={cardName}>
                {renderCard(index + 1, cardName)}
              </SwiperSlide>
            ))}
          </Swiper>
        </Styled.CardsContainer>
        <div className="pagination-bullets" />
      </Styled.Content>
    </Styled.Wrapper>
  );
}

export default SectionClients;
