import styled, { css, keyframes } from 'styled-components';

import { device } from 'styles/breakpoints';

const SpinKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  mode?:
    | 'google'
    | 'medium'
    | 'underline'
    | 'underlineGreen'
    | 'outlined'
    | 'small'
    | 'smallGreen'
    | 'mediumBlack'
    | 'mediumGreen';
  withMargin?: true;
  autoHeight?: boolean;
};

const buttonDefaultStyles = css`
  height: 70px;
  background-color: ${({ theme }) => theme.colors.green};
  border-radius: 6px;

  > p {
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover,
  &:focus {
    box-shadow: 0 2px 8px 0 rgb(0 212 161 / 20%);
  }

  &:disabled {
    box-shadow: none;
  }

  .btn-ring:after {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    margin: 2px;
    border-radius: 50%;
    border: 3px solid ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.white} transparent;
    animation: ${SpinKeyframe} 1.2s linear infinite;

    @media ${device.tablet} {
      width: 20px;
      height: 20px;
    }
  }
`;

const smallButtonStyles = css`
  ${buttonDefaultStyles}

  height: 41px;
  border-radius: unset;
  color: ${({ theme }) => theme.colors.white};
  font-size: 17px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: 1.23;
  max-width: 635px;
  background-color: ${({ theme }) => theme.colors.blackPallet6};
  padding: 10px 20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blackPallet7};
  }
`;

const smallGreenButtonStyles = css`
  ${buttonDefaultStyles}

  height: fit-content;
  width: fit-content;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: 17px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: 1.23;
  padding: 5px 10px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.greenPallet6};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grayPallet18};
  }
`;

const mediumButtonStyles = css`
  ${buttonDefaultStyles}

  height: 55px;
  border-radius: unset;
  color: ${({ theme }) => theme.colors.white};
  font-size: 17px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: 1.23;
  max-width: 635px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.greenPallet6};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grayPallet18};
  }

  @media ${device.tablet} {
    height: 41px;
  }
`;

const mediumBlackButtonStyles = css`
  ${buttonDefaultStyles}

  height: 55px;
  border-radius: unset;
  color: ${({ theme }) => theme.colors.white};
  font-size: 17px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  background-color: ${({ theme }) => theme.colors.blackPallet6};
  line-height: 1.23;
  max-width: 635px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blackPallet7};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grayPallet18};
  }

  @media ${device.tablet} {
    height: 41px;
  }
`;

const mediumGreenButtonStyles = css`
  ${buttonDefaultStyles}

  height: fit-content;
  width: fit-content;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: 17px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: 1.23;
  padding: 22px 44px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.greenPallet6};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grayPallet18};
  }

  @media ${device.tablet} {
    padding: 17px 34px;
  }
`;

const outlinedButtonStyles = css`
  ${buttonDefaultStyles}

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.blackPallet6};
  color: ${({ theme }) => theme.colors.blackPallet6};
  background-color: transparent;
  border-radius: unset;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blackPallet7};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const underlineButtonStyles = css`
  ${buttonDefaultStyles}

  height: unset;
  border-radius: unset;
  color: ${({ theme }) => theme.colors.grayPallet9};
  font-size: 17px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: 1.23;
  width: auto;
  background-color: transparent;
  text-decoration: underline;
  vertical-align: text-bottom;
  text-underline-offset: 5px;

  &:hover {
    color: ${({ theme }) => theme.colors.greenPallet6};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.grayPallet19};
  }

  &:hover,
  &:focus {
    box-shadow: none;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

const underlineGreenButtonStyles = css`
  ${buttonDefaultStyles}

  height: unset;
  border-radius: unset;
  color: ${({ theme }) => theme.colors.green};
  font-size: 17px;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: 1.23;
  width: auto;
  background-color: transparent;
  text-decoration: underline;
  vertical-align: text-bottom;
  text-underline-offset: 5px;

  &:hover {
    color: ${({ theme }) => theme.colors.greenPallet6};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.grayPallet19};
  }

  &:hover,
  &:focus {
    box-shadow: none;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const Button = styled.button<ButtonProps>`
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ mode }) => {
    switch (mode) {
      case 'google':
        return css`
          height: 58px;
          border: 1px solid rgba(43, 43, 43, 0.1);
          background-color: ${({ theme }) => theme.colors.white};
          > p {
            margin-left: 16px;
            color: ${({ theme }) => theme.colors.bluePallet1};
          }
        `;
      case 'small':
        return smallButtonStyles;
      case 'smallGreen':
        return smallGreenButtonStyles;
      case 'medium':
        return mediumButtonStyles;
      case 'mediumBlack':
        return mediumBlackButtonStyles;
      case 'mediumGreen':
        return mediumGreenButtonStyles;
      case 'underline':
        return underlineButtonStyles;
      case 'underlineGreen':
        return underlineGreenButtonStyles;
      case 'outlined':
        return outlinedButtonStyles;
      default:
        return buttonDefaultStyles;
    }
  }}

  ${({ withMargin }) =>
    withMargin
      ? css`
          margin-top: 24px;
        `
      : null}
`;
