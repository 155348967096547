import { memo } from 'react';
import NextImage from 'next/image';
import { FormattedMessage } from 'react-intl';
import { map } from 'lodash';

import { Container } from '../common/container';
import { SectionListedOnWrapper } from './styles';
import { companies } from './constants';
import messages from './messages';

function SectionListedOn() {
  return (
    <SectionListedOnWrapper>
      <Container size="md" className="listed">
        <h2>
          <FormattedMessage {...messages.title} />
        </h2>
        <p className="listed-description">
          <FormattedMessage
            {...messages.description}
            values={{ apos: <>&apos;</> }}
          />
        </p>
        <div className="listed-companies">
          {map(companies, (item) => (
            <div key={item}>
              <NextImage
                src={`/static/icons/companies/${item}.png`}
                width={179}
                height={66}
                quality={100}
              />
            </div>
          ))}
        </div>
      </Container>
    </SectionListedOnWrapper>
  );
}

export default memo(SectionListedOn);
