import { defineMessages } from 'react-intl';

export const scope = 'app.components.section-listed';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Your Home Listed on',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Boost your home{apos}s exposure with our platform network. We list on top real estate sites and marketplaces.',
  },
});
