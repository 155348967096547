import styled, { css } from 'styled-components';

export type ContainerProps = {
  size: 'lg' | 'md' | 'sm' | 'xl';
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  ${({ size }) => {
    switch (size) {
      case 'lg':
        return css`
          max-width: 1560px;
          padding: 0 20px;
        `;
      case 'md':
        return css`
          max-width: 1280px;
          padding: 0 20px;
        `;
      case 'sm':
        return css`
          max-width: 1064px;
          padding: 0 20px;
        `;
      default:
        return css`
          max-width: 2000px;
          padding: 0 20px;
        `;
    }
  }}
`;
