import styled from 'styled-components';

import { device } from 'styles/breakpoints';
import { media } from './constants';

const DiscoverLayout = styled.div`
  padding: 70px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.yellowPallet4};
  position: relative;

  .discover-left-img {
    background: url(${media.dots}) no-repeat;
    background-size: 148px 115px;
    position: absolute;
    width: 148px;
    height: 115px;
    left: 0;
    top: -35px;
  }

  .discover-right-ring {
    background: url(${media.ring}) no-repeat;
    background-size: 81px 66px;
    position: absolute;
    width: 81px;
    height: 66px;
    bottom: 0;
    right: 0;
  }

  @media ${device.tablet} {
    flex-direction: column-reverse;
    padding: 60px 20px;
    gap: 40px;

    .discover-left-img {
      width: 106px;
      height: 81px;
      left: 0;
      top: -17px;
      background-size: 106px 81px;
    }

    .discover-right-ring {
      display: none;
    }
  }
`;

const LeftLayout = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 625px;
  justify-content: center;
  width: 60%;

  h1 {
    font-size: 45px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    line-height: 1.2;
    margin-bottom: 20px;
  }

  p {
    font-size: 20px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    line-height: 1.2;
  }

  a {
    margin-top: 40px;
    font-size: 17px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    line-height: 1.23;
    text-align: center;
    padding: 17px 34px;
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.white};
    width: fit-content;

    &:hover {
      opacity: 0.8;
    }
  }

  @media ${device.tablet} {
    width: 100%;

    h1 {
      font-size: 25px;
    }

    p {
      font-size: 16px;
      line-height: 1.31;
    }

    a {
      padding: 10px 20px;
    }
  }
`;

const RightLayout = styled.div`
  margin-right: 90px;
  position: relative;

  .discover-right-img {
    background: url(${media.dotsLarge}) no-repeat;
    background-size: 159px 89px;
    position: absolute;
    width: 159px;
    height: 89px;
    right: -47px;
    top: -35px;
  }

  @media ${device.laptop} {
    margin-right: 0;
  }

  @media ${device.tablet} {
    display: flex;
    justify-content: center;

    .discover-right-img {
      display: none;
    }

    .right-layout-ring {
      background: url(${media.ellipse}) no-repeat;
      background-size: 40px 76px;
      position: absolute;
      width: 40px;
      height: 76px;
      bottom: 0;
      right: -20px;
    }
  }
`;

export default { DiscoverLayout, LeftLayout, RightLayout };
