import { defineMessages } from 'react-intl';

export const scope = 'app.components.section-hero';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Start Making Money The Day You List',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Earn a daily cash bonus while your home attracts buyers. No upfront costs, cancel any time and only 1% at closing.',
  },
  fdicText: {
    id: `${scope}.fdicText`,
    defaultMessage: 'All saving deposits provided are FDIC insured',
  },
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Start Listing Now',
  },
  wantToTalk: {
    id: `${scope}.wantToTalk`,
    defaultMessage: 'Want to talk?',
  },
  callExpert: {
    id: `${scope}.callExpert`,
    defaultMessage: 'Call our expert',
  },
  hiddenInfo: {
    id: `${scope}.hiddenInfo`,
    defaultMessage:
      'Pricing: 3%, Cashback option: Invest in a Richr Money account & receive 2% cashback for a net fee of 1% Opt-out option: Pay 3% without cashback',
  },
});
