export type CardNames = 'cardOne' | 'cardTwo' | 'cardThree' | 'cardFour';

export const cards: CardNames[] = [
  'cardOne',
  'cardTwo',
  'cardThree',
  'cardFour',
];

export const media = {
  stars: '/static/images/home/stars.png',
};
