import styled from 'styled-components';

import { device } from 'styles/breakpoints';

const Wrapper = styled.div`
  display: flex;
  gap: 90px;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column;
    gap: 40px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;

  @media ${device.laptop} {
    flex: 1;
  }

  @media ${device.tablet} {
    justify-content: center;
  }
`;

const Content = styled.div`
  padding-right: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  h1 {
    margin-bottom: 20px;
    font-size: 45px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
    line-height: 1.2;
  }

  p {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 1.2;
    max-width: 541px;
  }

  a {
    font-size: 17px;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.white};
    line-height: 1.21;
    text-align: center;
    padding: 17px 34px;
    height: initial;
    width: fit-content;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.8;
    }
  }

  @media ${device.smallLaptop} {
    padding: 0 20px;

    h1 {
      margin-bottom: 15px;
      font-size: 25px;
    }

    p {
      font-size: 16px;
      line-height: 1.31;
      margin-bottom: 20px;
    }

    a {
      padding: 10px 22px;
    }
  }

  @media ${device.tablet} {
    padding: 0 20px 80px;
  }
`;

export default { Wrapper, ImageWrapper, Content };
