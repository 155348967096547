export const media = {
  rightArrow: '/static/images/home/right-arrow.svg',
  review1: '/static/images/home/review-1.jpg',
  review2: '/static/images/home/review-2.jpg',
  review3: '/static/images/home/review-3.jpg',
  review4: '/static/images/home/review-4.jpg',
  homeBackground: '/static/images/home/home.jpg',
  homeBackgroundMobile: '/static/images/home/home-mobile.jpg',
  reviewOneMobile: '/static/images/home/review-1-mobile.jpg',
  reviewTwoMobile: '/static/images/home/review-2-mobile.jpg',
  reviewThreeMobile: '/static/images/home/review-3-mobile.jpg',
  reviewFourMobile: '/static/images/home/review-4-mobile.jpg',
  fdic: '/static/images/home/fdic.png',
};
