import { FormattedMessage, useIntl } from 'react-intl';
import Image from 'next/image';
import { ReactElement } from 'react';
import { isEqual } from 'lodash';

import messages from './messages';
import { Container } from '../common/container';
import { SectionDescription } from '../section-featured/styles';
import {
  ImageWrapper,
  LogoText,
  LogoWrapper,
  Note,
  SellWithRichrWrapper,
  StepCounter,
  StepDescription,
  StepLayout,
  StepListItem,
  StepListWrapper,
  StepsContainer,
  StepTitle,
  StepWrapper,
  TitlesWrapper,
} from './styles';
import { media } from './constants';
import { useWindow } from 'hooks/useWindow';

function SectionSellWithRichr() {
  function renderTitleImages() {
    return (
      <TitlesWrapper>
        <div className="badge-insurance-image">
          <Image
            src="/static/images/benefits/badge-insurance.png"
            width={302}
            height={76}
            alt={messages.insuranceAltText.defaultMessage}
          />
        </div>
        <div>
          <Image
            src="/static/images/benefits/badge-fidelity.png"
            width={286}
            height={65}
            alt={messages.fidelityAltText.defaultMessage}
          />
        </div>
      </TitlesWrapper>
    );
  }

  function renderSteps() {
    return (
      <StepsContainer>
        <StepLayout>
          <Step step={1} stepKey="stepOne" />
          <ImageWrapper>
            <Image
              src="/static/images/benefits/step-one.jpg"
              width={496}
              height={515}
              alt={messages.stepOneAltText.defaultMessage}
            />
          </ImageWrapper>
        </StepLayout>
        <StepLayout direction="reverse">
          <ImageWrapper leftAligned>
            <Image
              src="/static/images/benefits/step-two.jpg"
              width={496}
              height={515}
              alt={messages.stepTwoAltText.defaultMessage}
            />
          </ImageWrapper>
          <Step step={2} stepKey="stepTwo">
            {renderTitleImages()}
          </Step>
        </StepLayout>
        <StepLayout>
          <Step step={3} stepKey="stepThree" />
          <ImageWrapper>
            <Image
              src="/static/images/benefits/step-three.webp"
              width={496}
              height={515}
              alt={messages.stepThreeAltText.defaultMessage}
            />
          </ImageWrapper>
        </StepLayout>
      </StepsContainer>
    );
  }

  return (
    <SellWithRichrWrapper>
      <Container size="lg">
        <h2>
          <FormattedMessage {...messages.header} />
        </h2>
        <SectionDescription>
          <FormattedMessage {...messages.description} />
        </SectionDescription>
        {renderSteps()}
      </Container>
    </SellWithRichrWrapper>
  );
}

function Step({ step, stepKey, children }: StepProps) {
  const { formatMessage } = useIntl();
  const { w: width } = useWindow();

  function renderListItem({
    pointNumber,
    shouldRenderImage,
  }: RenderListItemProps) {
    return (
      <StepListItem adjustMargin={shouldRenderImage}>
        <span>
          <FormattedMessage {...messages[`${stepKey}${pointNumber}`]} />
        </span>
        {shouldRenderImage && (
          <>
            <LogoText>
              <FormattedMessage {...messages.stepThreeLogoText} />
            </LogoText>
            <LogoWrapper>
              <Image
                src={media.fdic}
                width={width > 768 ? 104 : 60}
                height={width > 768 ? 84 : 48}
                alt={formatMessage(messages.FDICAltText)}
              />
              <Image
                src={media.sipc}
                width={width > 768 ? 97 : 73}
                height={width > 768 ? 54 : 40}
                alt={formatMessage(messages.SIPCAltText)}
              />
            </LogoWrapper>
          </>
        )}
      </StepListItem>
    );
  }

  return (
    <StepWrapper>
      <StepCounter>{step}</StepCounter>
      <StepTitle>
        <FormattedMessage {...messages[`${stepKey}Title`]} />
      </StepTitle>
      <StepDescription>
        <FormattedMessage {...messages[`${stepKey}SubText`]} />
      </StepDescription>
      <StepListWrapper>
        {renderListItem({ pointNumber: `PointOne` })}
        {renderListItem({
          pointNumber: `PointTwo`,
          shouldRenderImage: isEqual(step, 3),
        })}
        {renderListItem({
          pointNumber: `PointThree`,
        })}
        {renderListItem({ pointNumber: `PointFour` })}
        {isEqual(step, 3) && (
          <Note>
            <FormattedMessage {...messages.stepThreeNote} />
          </Note>
        )}
      </StepListWrapper>
      {children}
    </StepWrapper>
  );
}

export interface StepProps {
  step: number;
  stepKey: 'stepOne' | 'stepTwo' | 'stepThree';
  children?: ReactElement;
}

interface RenderListItemProps {
  pointNumber: 'PointOne' | 'PointTwo' | 'PointThree' | 'PointFour';
  shouldRenderImage?: boolean;
}

export default SectionSellWithRichr;
