import { defineMessages } from 'react-intl';

export const scope = 'app.components.section-discover';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Discover Your Home{apos}s True Value Today',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Unlock the potential of your home and make better decisions with our free home valuation tool. Get an accurate estimate in seconds and take the first step towards achieving your real estate goals.',
  },
  button: {
    id: `${scope}.button`,
    defaultMessage: 'Get free home valuation',
  },
});
