function Quotes() {
  return (
    <svg
      width="37"
      height="30"
      viewBox="0 0 37 30"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5208 0V15H30.1291C30.1291 19.7326 26.2738 23.5714 21.5208 23.5714V30C29.8412 30 36.5854 23.2848 36.5854 15V0H21.5208ZM0 15H8.60832C8.60832 19.7326 4.75303 23.5714 0 23.5714V30C8.32044 30 15.0646 23.2848 15.0646 15V0H0V15Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Quotes;
