import styled from 'styled-components';

import { device } from 'styles/breakpoints';

export const SectionListedOnWrapper = styled.section`
  margin: 100px 0 95px;
  display: flex;
  justify-content: center;

  @media ${device.tablet} {
    margin-top: 60px 0 75px;
  }

  .listed {
    > p {
      text-align: center;
      max-width: 636px;
      margin: 0 auto 55px;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.2;

      @media ${device.tablet} {
        font-size: 16px;
        line-height: 1.31;
        margin: 0 auto 36px;
      }
    }

    > h2 {
      font-size: 45px;
      line-height: 1.2;
      color: ${({ theme }) => theme.colors.bluePallet1};
      position: relative;
      text-align: center;
      margin-bottom: 20px;

      @media ${device.laptop} {
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 20px;
      }

      @media ${device.tablet} {
        padding: 0;
        font-size: 25px;
        line-height: 36px;
        margin-bottom: 15px;
      }
    }

    &-companies {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      column-gap: 40px;
      row-gap: 20px;
      max-width: 1078px;
      margin: auto;

      @media ${device.tablet} {
        gap: 30px;
        flex-direction: column;

        div {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;
