import { defineMessages } from 'react-intl';

export const scope = 'app.components.SectionFeatured';

const messages = defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Join the Richr Revolution',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage:
      'Sell your home faster, with lower fees. Maximize your wealth when you invest your savings with Richr Money.',
  },
  featureHouse: {
    id: `${scope}.featureHouse`,
    defaultMessage: 'Free listing on your local MLS',
  },
  featureSupport: {
    id: `${scope}.featureSupport`,
    defaultMessage: 'Unlimited concierge support',
  },
  featureTeam: {
    id: `${scope}.featureTeam`,
    defaultMessage: 'Dedicated title closing team',
  },
  featureFee: {
    id: `${scope}.featureFee`,
    defaultMessage: 'Daily Cash Rewards',
  },
  featureRiskFree: {
    id: `${scope}.featureRiskFree`,
    defaultMessage: 'No commitment. Cancel anytime',
  },
  claimCashBonus: {
    id: `${scope}.claimCashBonus`,
    defaultMessage: 'Claim Cash Bonus',
  },
});

export type MessageKeys = keyof typeof messages;

export default messages;
