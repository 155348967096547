import { ReactNode } from 'react';

import HomepageFooter from 'components/homepage-footer';
import HomepageHeader from 'components/homepage-header';
import Styled from './styles';

function Layout({
  children,
  className,
  renderFooter,
  isWhiteLinks,
}: LayoutProps) {
  return (
    <Styled.LayoutWrapper className={className}>
      <HomepageHeader isWhiteLinks={isWhiteLinks} />
      {children}
      {renderFooter && <HomepageFooter />}
    </Styled.LayoutWrapper>
  );
}

Layout.defaultProps = {
  renderFooter: true,
};

interface LayoutProps {
  children: ReactNode;
  className?: string;
  renderFooter?: boolean;
  isWhiteLinks?: boolean;
}

export default Layout;
