export function formatCurrency({
  currencyCode,
  amount,
}: {
  currencyCode?: string;
  amount: number | bigint;
}) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
}
